import styled from 'styled-components'
import { rhythm, media, colspan } from '../settings/styles'

const Grid = styled.section`
  display: flex;
  flex-direction: ${props => (props.flipped ? 'row-reverse' : 'row')};
  margin-top: ${rhythm(4)};
  margin-bottom: ${rhythm(4)};
  align-items: ${props => (props.top ? 'flex-start' : 'center')};

  ${media.tablet`
    flex-wrap: wrap;
  `};
`

export default Grid

export const Col = styled.section`
  text-align: ${props => (props.right ? 'right' : 'left')};
  transform: translateX(${props => colspan(props.pullRight)});
  width: ${props => (props.span ? colspan(props.span) : '50%')};
  margin-left: ${props => (props.gutterLeft ? colspan(props.gutterLeft) : '0')};
  margin-right: ${props =>
    props.gutterRight ? colspan(props.gutterRight) : '0'};

  padding: ${props => (props.padded ? rhythm(3) : 0)};
  padding-top: ${props => (props.padded && !props.flat ? rhythm(3) : 0)};

  ${media.tablet`
    text-align: left;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0; 
    margin-bottom: ${rhythm(1)};
    transform: none;
  `};
`

export const ColFooter = styled.div`
  margin-top: ${rhythm(2)};
`

export const BreakoutContent = styled.div`
  margin-left: -${props => colspan(props.breakoutLeft)};
  margin-right: -${props => colspan(props.breakoutRight)};

  ${media.tablet`
    margin-left: 0;
    margin-right: 0;
  `};
`
