import React from 'react'
import Layout from '../components/Layout.js'
import Img from 'gatsby-image'
import styled from 'styled-components'

import HeroSlider from '../components/HeroSlider'

import { rhythm, media, colors } from '../settings/styles'
import Body from '../components/Body'

import MoreLink from '../components/MoreLink'
import LargeMoreLink from '../components/LargeMoreLink'
import CTA from '../components/CTA'
import FadeIn from '../components/FadeIn'

import Grid, { BreakoutContent, Col, ColFooter } from '../components/Grid'

import alix from '../img/alix.png'
import gesaey from '../img/ge_saey_photography_logo.jpg'

import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import CTABall from '../components/CTABall'

const Seperator = styled.div`
  opacity: 0.65;
  border-left: 1px solid #d1898f;
  height: 140px;
  margin: 2rem auto;
  width: 0;

  ${media.phone`
    height: 50px;
  `};
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 3.5rem;
  line-height: 1.1;
  color: #000000;
  text-transform: ${props => (props.lowercase ? 'lowercase' : 'none')};
  max-width: 8em;
  margin: ${rhythm(3)} auto;

  /* hide the title */
  visibility: hidden;
  position: absolute;
  pointer-event: none;

  ${media.phone`
    font-size: 2rem;
  `};
`

const FancyTitle = styled.h2`
  font-weight: 300;
  font-size: 2.8rem;
  letter-spacing: 0.07em;
  line-height: 1.1;
  text-transform: uppercase;
  color: ${colors.brandText};
  span {
    display: block;
  }

  ${media.phone`
    font-size: 2rem;
    text-align: center;
    span {
      display: inline;
    }
  `};
`

const PageFooter = styled.div`
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.2;
  margin: ${rhythm()} 0;
  text-transform: lowercase;

  img {
    margin-top: ${rhythm(1)};
    margin-left: 0.25em;
    margin-right: 0.25em;
    vertical-align: top;
  }
`
const IndexPage = props => {
  const { data } = props

  const {
    title,
    body: { processed: html },
    field_description: description,

    field_slides: field_slidesMeta,
    field_image: mainImageMeta,
    field_cta_image: ctaImageMeta,
    field_extra_image_1: extraImage1Meta,
    field_extra_image_2: extraImage2Meta,

    relationships: {
      field_slides: slides,
      field_image: mainImage,
      field_cta_image: ctaImage,
      field_extra_image_1: extraImage1,
      field_extra_image_2: extraImage2,
    },
  } = data.cmsPage

  return (
    <Layout location={props.location}>
      <Helmet>
        <title>{title} | Wow my day</title>
        <meta name="description" content={description} />
        <meta
          name="og:image"
          content={mainImage.localFile.childImageSharp.ogImage.src}
        />
      </Helmet>
      <FadeIn className="l-constrained">
        <div style={{ position: 'relative' }}>
          <HeroSlider images={slides} imageMeta={field_slidesMeta} />
          <CTABall></CTABall>
        </div>

        <CTA />
        <span id="content" />
        <Seperator />
        <Title lowercase>{title}</Title>
        <Grid flipped>
          <Col>
            <BreakoutContent breakoutRight={1}>
              <Img
                alt={mainImageMeta.alt}
                fluid={mainImage.localFile.childImageSharp.fluid}
              />
            </BreakoutContent>
          </Col>
          <Col padded right>
            <Body dangerouslySetInnerHTML={{ __html: html }} />
            <ColFooter>
              <MoreLink to="/about">Read more</MoreLink>
            </ColFooter>
          </Col>
        </Grid>
        <Grid>
          <Col>
            <BreakoutContent breakoutLeft={1} breakoutRight={1}>
              <Img
                alt={ctaImageMeta.alt}
                fluid={ctaImage.localFile.childImageSharp.fluid}
              />
            </BreakoutContent>
          </Col>
          <Col padded pullRight={1}>
            <FancyTitle>
              {'WOW your day?'.split(' ').map((word, index) => (
                <span key={index}>{word} </span>
              ))}
            </FancyTitle>
            <ColFooter>
              <MoreLink to="/contact">Give us a shout</MoreLink>
            </ColFooter>
          </Col>
        </Grid>
        <Grid top>
          <Col span={2} gutterLeft={1} gutterRight={1}>
            <Img
              alt={extraImage1Meta.alt}
              fluid={extraImage1.localFile.childImageSharp.fluid}
            />
            <ColFooter>
              <LargeMoreLink to="/projects">more pictures please</LargeMoreLink>
            </ColFooter>
          </Col>
          <Col>
            <Img
              alt={extraImage2Meta.alt}
              fluid={extraImage2.localFile.childImageSharp.fluid}
            />
          </Col>
        </Grid>
      </FadeIn>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query home {
    cmsPage: nodeHome {
      title
      body {
        processed
      }
      field_description
      field_slides {
        alt
      }
      field_image {
        alt
      }
      field_cta_image {
        alt
      }
      field_extra_image_1 {
        alt
      }
      field_extra_image_2 {
        alt
      }

      relationships {
        field_slides {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        field_image {
          localFile {
            id
            childImageSharp {
              ogImage: resize(width: 1200, height: 630) {
                src
              }
              fluid(maxWidth: 613, maxHeight: 495) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_cta_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 738, maxHeight: 659) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_extra_image_1 {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 487, maxHeight: 728) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_extra_image_2 {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 487, maxHeight: 728) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
