import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled, { keyframes } from 'styled-components'

import { media } from '../settings/styles'

const Slider = styled.div`
  position: relative;
`

const SlideWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const ScaleIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
`

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Slide = styled.div`
  opacity: 0;
  transform: scale(1.15);
  position: relative;

  &.active {
    animation: 10s ${ScaleIn} linear forwards, 1s ${FadeIn} linear forwards;
    z-index: 10;
  }

  &.active:first-child:last-child {
    animation: 0;
    opacity: 1;
    transform: scale(1);
  }

  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Pager = styled.div`
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: 1rem;

  ${media.phone`
    display: none;
  `};
`

const Page = styled.button`
  border: 0;
  border-radius: 0;
  background: none;
  font-family: inherit;
  font-size: 12px;
  color: #d1898f;
  display: block;

  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  display: flex;
  align-items: center;

  margin-top: 1em;

  &::before {
    display: block;
    content: '';
    border-top: 1px solid currentColor;
    margin-top: -1px;
    margin-right: 0.5em;
    transition: 300ms ease-in-out;
    width: ${props => (props.active ? '1em' : 0)};
  }

  &:focus {
    outline: none;
  }
`

class HeroSlider extends Component {
  slideInterval = null
  state = {
    activeSlide: 0,
    maxSlides: this.props.images.length,
  }

  constructor(props) {
    super(props)
    this.resetSlideInterval = this.resetSlideInterval.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
  }

  resetSlideInterval() {
    if (this.slideInterval) clearInterval(this.slideInterval)
    this.slideInterval = setInterval(this.nextSlide, 8000)
  }

  nextSlide() {
    let index = this.state.activeSlide + 1
    index = index >= this.state.maxSlides ? 0 : index
    this.setState({ prevSlide: this.state.activeSlide, activeSlide: index })
  }

  render() {
    const { props } = this
    const { images, imageMeta } = props
    return (
      <Slider>
        <SlideWrapper>
          {images.map((image, index) => (
            <Slide
              key={`slide-${image.id}`}
              className={[
                index === this.state.activeSlide ? 'active' : '',
                index === this.state.prevSlide ? 'goingOut' : '',
              ].join(' ')}
            >
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={imageMeta[index].alt}
              />
            </Slide>
          ))}
        </SlideWrapper>
        {images.length > 1 && (
          <Pager>
            {images.map((image, index) => (
              <Page
                key={`page-${image.id}`}
                active={index === this.state.activeSlide}
                onClick={() => {
                  this.resetSlideInterval()
                  this.setState({
                    prevSlide: this.state.activeSlide,
                    activeSlide: index,
                  })
                }}
              >
                0{index + 1}
              </Page>
            ))}
          </Pager>
        )}
      </Slider>
    )
  }
}

export default HeroSlider
