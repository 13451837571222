import React from 'react'
import styled from 'styled-components'

import arrow from '../img/arrow-right-white.svg'
import { media } from '../settings/styles'
import { Link } from 'gatsby'

const StyledCTA = styled(Link)`
  background: #d2888e;
  color: #fff;
  display: block;
  width: 40%;
  margin-top: -30px;
  padding: 30px 30px;
  position: relative;
  z-index: 50;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;

  ${media.desktop`
    width:100%;
    margin-top: 30px;
  `}

  &:hover {
    img {
      transform: translateX(1em);
    }
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  line-height: 1;

  ${media.desktop`
    font-size: 21px;
  `}
`

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: #ffffff;
  line-height: 23px;
  margin-top: 0.5em;

  img {
    margin-left: 0.7em;
    transition: 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`

export default function CTA() {
  return (
    <StyledCTA to="/projects">
      <Title>Flowers for your event</Title>
      <SubTitle>
        discover our projects
        <img src={arrow} alt="" />
      </SubTitle>
    </StyledCTA>
  )
}
