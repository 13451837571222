import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { colors } from '../settings/styles'

import MultilineTitle from './MultilineTitle'

import arrow from '../img/more-arrow.svg'

const Icon = styled.img`
  display: block;
  margin-top: 1.2em;
  transition: 300ms;
`

const MoreLink = styled(Link)`
  text-decoration: none;
  color: ${colors.brandText};

  &:hover ${Icon} {
    transform: translateX(0.2em);
  }
`

const Label = MultilineTitle.withComponent('span')

const LargeMoreLink = props => (
  <MoreLink to={props.to}>
    <Label>{props.children}</Label>
    <Icon src={arrow} />
  </MoreLink>
)

export default LargeMoreLink
