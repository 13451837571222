import React from 'react'
import styled from 'styled-components'

const StyledBall = styled.a`
  display: block;
  position: absolute;
  top: 80px;
  right: -80px;

  background: #d2888e;
  color: #fff;
  text-decoration: none;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 160px;
  border-radius: 100%;

  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;

  -webkit-font-smoothing: antialiased;

  transition: 100ms ease-in-out;

  z-index: 10;

  @media (max-width: 700px) {
    /* display: none; */
    width: 80px;
    height: 80px;
    font-size: 12px;
    top: auto;
    right: 5px;
    bottom: 5px;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.9);
  }
`

function CTABall() {
  return (
    <StyledBall href="https://shop.wowmyday.be/">
      Flowers
      <br />
      on Friday?
    </StyledBall>
  )
}

export default CTABall
