import styled from 'styled-components'

import { media } from '../settings/styles'

const MultilineTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.2;
  word-spacing: 50em;
  overflow: hidden;

  ${media.phone`
    font-size: 1.5rem;
    word-spacing: auto;
  `};
`

export default MultilineTitle
