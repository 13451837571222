import styled from 'styled-components'
import { Link } from 'gatsby'

import { colors } from '../settings/styles'

const MoreLink = styled(Link)`
  text-decoration: none;
  color: ${colors.brandText};

  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.5em;
  text-transform: uppercase;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1.5em;
    border-top: 2px solid currentColor;
    margin-top: -3px;
    margin-right: 1em;
  }
`

export default MoreLink
